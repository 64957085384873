import React, { useEffect, useState, createContext } from 'react'
import { getCoins } from './ApiCall';

export const StateContext = createContext();

export const State = props => {
    const [coins, setCoins] = useState([]); //selecting coins
    const [coinName, setCoinName] = useState(''); //selects and shows coin name
    const [coinValue, setCoinValue] = useState(''); //set coin value = coin price. value attributes returns coin price
    const [quantity, setQuantity] = useState('') //quantity of coins state
    const [Investment, setInvestment] = useState(''); //state for calculating initial investment
    const [ProPrice, setProPrice] = useState(''); //Projected price state
    const [ProReturns, setProReturns] = useState(''); //Projected (price) returns
    const [difference, setDiffernce] = useState(''); //Profit / difference calculator
    const [profitHook, setProfitHook] = useState('');
    const [showResult, setShowResult] = useState(false); 

    //and a state for the difference - this one is just calculation, doesn;t take in any value

    useEffect(() => {
        getCoins()
        .then(data => {
            setCoins(data);
            console.log(data.length);
        })
    },[])


    return (
            <StateContext.Provider 
                value={{
                    coins: [coins, setCoins], 
                    coinName: [coinName, setCoinName], 
                    coinValue: [coinValue, setCoinValue], 
                    quantity: [quantity, setQuantity] ,
                    Investment: [Investment, setInvestment],
                    ProPrice: [ProPrice, setProPrice],
                    ProReturns: [ProReturns, setProReturns],
                    difference: [difference, setDiffernce],
                    pHook: [profitHook, setProfitHook],
                   resultsShow: [showResult, setShowResult],
                }}>
                {props.children}
            </StateContext.Provider>
        
    )
}
