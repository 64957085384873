import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { State} from "./components/State";
    
   // put your tracking id here



ReactDOM.render(

    <BrowserRouter>
      <React.StrictMode>
      <State>
          <App />
      </State>
      </React.StrictMode>
    </BrowserRouter>
    ,
  document.getElementById('root')

);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
