import React from 'react';

const Footer = () => {
    return (
        <div>
            <center>
                <a href="http://twitter.com/geniusyinka" target="blank" class="mt-5 mb-5 fa fa-twitter fa-2x"></a>
            </center>
        </div>
    );
}

export default Footer;
