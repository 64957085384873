import React, {useContext, useEffect, useState } from 'react'
import { StateContext } from './State';
import NumberFormat from 'react-number-format';
import styles from '../index.css';
import Fade from 'react-reveal/Fade';
import {CSSTransition} from 'react-transition-group';

function Input() {
    const {coins, coinName, coinValue, Investment, quantity, ProPrice, ProReturns, difference, pHook, resultsShow} = useContext(StateContext); // variable destructuring
    const [coin, setCoin] = coins; // coins from api data
    const [coinNames, setCoinNames] = coinName; //coins name in name attribute
    const [coinValues, setCoinValues] = coinValue; //coins value(price) gotten from value attrribute
    const [coinQuantity, setCoinQuantity] = quantity;
    const [InvestmentCalc, setInvestmentCalc] = Investment; //
    const [ProPriceValues, setProPriceValues] = ProPrice;
    const [ProReturnsCalc, setProReturnsCalc] = ProReturns;
    const [profit, setProfit] = difference; // projected returns - initial investment
    const [profitHook, setProfitHook] = pHook;
    const [unitPrice, setUnitPrice] = useState('');
    const [showResults, setShowResults] = resultsShow; 
    const [slide, setSlide] = useState(0);
    const [error, setError] = useState(false);
    const [inputSlide, setInputSlide] = useState(false)


    const handleHide = () => {
        setShowResults(false);
        setUnitPrice('');
        setCoinQuantity('');
        setProPriceValues('');
        setInvestmentCalc('');
        setProReturnsCalc('');
        setProfit('');
        
}

// const handleShow = () => {
// setShowResults(true)
// }



    //notes:
    // make in info icon that explains what each select option means oh hoevr. 
    //e.g - "quantity" input box will say "How much eth(insert coin name here) do you own. 
    // i want to write a coin unit price that sits directly beside the coin and can be editbale which inturn affects the initial investment calculation...
    //add an option where people can select an amount instead of units... like, how many coins will $100 get me, and not entirely about how much will x amount of coins be. 
    const changeCoin = (e) => {
        setCoinValues(e.target.value); //changes coin by value
        setCoinNames(e.target[e.target.selectedIndex].getAttribute('data-label')); //gets name of coin by html attribute
        setCoinQuantity(''); //resets values to null on change of coin
        setProPriceValues(''); //same as above
        setUnitPrice('');
        setInvestmentCalc('');
        setProReturnsCalc('');
        setProfit('');
        setShowResults(false);
        } 



    useEffect(() => {
         let isMounted = true;
        setProfitHook(profit);
        setProfit(profit);
        setUnitPrice(coinValues);
        return () => {
            isMounted = false
        };
    }, [coinNames,coinValues]);


    const onChangeHandleQuantity = (e) => {
        //const { name, value } = e.target
        setCoinQuantity(e.target.value);
        };

    // function for setting projected price values
    const onChangeHandleValues = (e) => {
         //const { name, value } = e.target;
         setProPriceValues(e.target.value);
         };


    const onChangeUnitePrice = (e) => {
        setUnitPrice(e.target.value)
    }
    

    // function for handling calculations on keyup
    const onKeyUpHandle = (e) => {
        setInvestmentCalc((coinQuantity * unitPrice).toFixed(2)); //initial investment math
        setProReturnsCalc((ProPriceValues * coinQuantity).toFixed(2)); //projected returns math
        //setProfit(ProReturnsCalc - InvestmentCalc); //profit returns math
        //first two original solutions
        //setInvestmentCalc(coinQuantity * coinValues);
        ////////////////
        // setProReturnsCalc(unitPrice * coinQuantity);
        // setProfit(ProReturnsCalc - InvestmentCalc);
    }

    const minus = (e) => {
        // if (coinQuantity == 0){
        //     console.log('field cannot be empty')
        //     setError(true);
        // }
        // else if (ProPriceValues == 0) {
        //     console.log("field cannot be empty");
        //     setError(true);
        //     setProfit("you did not make any profit *sad face*")
        // } else if (ProPriceValues < unitPrice) {
        //     console.log("its low")
        //     setShowResults(true);
        //     setProfit("you're at a loss")
        // }
        // else if (ProPriceValues >= unitPrice) {
        //     setProfit((ProReturnsCalc - InvestmentCalc).toFixed(2));
        //     console.log('star boy!')
        //     setSlide(1);
        //     setInputSlide(true)
        //     setShowResults(true);
        //     setProfit((ProReturnsCalc - InvestmentCalc).toFixed(2));

        // }
        
        //if this = null echo: you did not make any profit *sad face*
        
        // setSlide(1);
        setShowResults(true);
         setProfit((ProReturnsCalc - InvestmentCalc).toFixed(2));
    }

    // window.setTimeout(() => {
    //     setError(null)
    //   }, 2500);
        return (
            <div className="input2">
                <Fade big>
                <div className="inline-block relative">
                    <div>
                        <label htmlFor="price" class="label block text-sm font-medium text-gray-700">Select Coin</label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                            <div className="absolute inset-y-0 right-0 pr-28 flex items-center pointer-events-none">
                                <span className="text-gray-500 sm:text-sm">
                                     $
                                </span>
                            </div>
                            <input type="number" name="price" id="price" pattern="[0-9]*" 
                                className="text-light-black focus:ring-0 py-5 border-none  block w-72 pl-7 pr-7 text-xl  rounded-t-md rounded-bl-md text-right" 
                                placeholder="0.00"
                                value={unitPrice}
                                onChange={onChangeUnitePrice}
                                onKeyUp={onKeyUpHandle}/>
                                    
                            <div class="absolute inset-y-0 left-0 flex items-center">
                                <label htmlFor="currency" class="sr-only">Currency</label>
                                <select 
                                    className="font-medium h-full py-0 pl-7 pr-7 focus:ring-0 border-none border-transparent bg-transparent text-gray-500 text-l rounded-l-lg"
                                    value={coinValues}
                                    label={coinNames}
                                    onChange={changeCoin}>
                                        <option value="0.00" data-label="Custom Coin">Custom</option>
                                    {coin.map((coin, index) => ( 
                                        
                                        <option value={coin.current_price} key={index} name={coin.symbol} data-label={coin.name}>{coin.name}</option>
                                            ))} 

                                </select>
                            </div>
                        </div>
                    </div>
                        <label htmlFor="price" className="label block text-sm font-medium text-gray-700">Quantity</label>
                        <div class="mt-1 relative rounded-md shadow-sm">
                            <input type="number" pattern="[0-9]*" className="text-light-black font-normal text-xl border-none focus:ring-0 inset-y-0 right-0 rounded-t-md rounded-bl-md focus:border-dark-green0 block w-72 pl-7 pr-12 py-5 border-light-green0 " 
                            placeholder="1" 
                            name=""
                            value={coinQuantity}
                            onChange={onChangeHandleQuantity}
                            onKeyUp={onKeyUpHandle}
                            required
                            />
                        </div> 
                    
                       
                       <label htmlFor="price" className="label block text-sm font-medium text-gray-700">Projected Price</label>
                       <div className="mt-1 relative rounded-md shadow-sm">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span class="text-gray-500 sm:text-sm">
                                $
                            </span>
                            </div>

                                <input
                                type="number" pattern="[0-9]" name="price" id="price" 
                                className="focus:outline-none focus:ring-0  text-light-black font-normal text-xl border-none py-5 inset-y-0 right-0 block w-72 pl-7 pr-12 border-light-green0 rounded-t-md rounded-bl-md" 
                                placeholder="0.00"
                                value={ProPriceValues}
                                name=""
                                onChange={onChangeHandleValues}
                                onKeyUp={onKeyUpHandle}
                                thousandSeparator={false} />
                                
                        </div>
                <center>
                    
                    <button 
                    onClick={minus} 
                    className="bg-dark-green0 hover:bg-dark-green0 text-white mr-5 font-bold py-2 px-4 rounded mt-5"
                    onAnimationEnd={() => setSlide(0)}
                    slide={slide}
                    >Calculate Profit
                    </button>
                    <button 
                    onClick={handleHide} 
                    className="bg-dark-green0 hover:bg-dark-green0 text-white font-bold py-2 px-4 rounded mt-5"
                    >Reset</button>
                        {/* <button onClick={handleShow}>Show</button> */}
                        {/* <button onClick={handleHide}>Hide</button>  */}
    
                        {/* <p className="text-light-red transform">{error ? "" : null }</p> */}
                    </center>
                        <div className={`alert alert-success pt-4 ${error ? 'alert-shown' : 'alert-hidden'}`}>
                                <p>Fields cannot be empty</p>
                        </div>
                </div>
                </Fade>
            </div>
        );
}


export default  Input