import React, {useContext, useState } from 'react'
import { StateContext } from './State';
import NumberFormat from 'react-number-format';
import Fade from 'react-reveal/Fade';

function Results() {
    const {coins, coinName, coinValue, Investment, quantity, ProPrice, ProReturns, difference, resultsShow} = useContext(StateContext); // variable destructuring
    const [coin, setCoin] = coins; // coins from api data
    const [coinNames, setCoinNames] = coinName;
    const [coinValues, setCoinValues] = coinValue;
    const [InvestmentCalc, setInvestmentCalc] = Investment; //
    const [coinQuantity, setCoinQuantity] = quantity;
    const [ProPriceValues, setProPriceVales] = ProPrice;
    const [ProReturnsCalc, setProReturnsCalc] = ProReturns;
    const [profit, setProfit] = difference
    const [showResults, setShowResults] = resultsShow; 




    //calculating profits is projected price - investment calc... 
    //now, our app need to be smart enough to *not* use the word profit if the returned value is at the negative... 
    return (
        <div>
           
         {showResults ? 
            <Fade delay={200} top cascade>
            <div className="results result-hide pl-10">
            <div className="mb-2">
                <label htmlFor="price" className="text-light-green0 font-normal text-3xl">Initial Investment:</label>
                    {/* <NumberFormat thousandSeparator={true} prefix={'$'} displayType={'text'} value={InvestmentCalc} /> */}
                <p className="libre select-all text-light-black text-3xl"> <NumberFormat thousandSeparator={true} prefix={'$'} displayType={'text'} value={InvestmentCalc} suffix={'💰'} /> </p>
            </div>

                {/* <label for="price" class="block text-sm font-medium text-gray-700">Projected Return:</label>
                <NumberFormat thousandSeparator={true} prefix={'$'} displayType={'text'} value={ProReturnsCalc} />
                <p class="block text-sm font-medium text-gray-700">Your current value is  
                    <NumberFormat
                     thousandSeparator={true} prefix={' $'} displayType={'text'} value={ProReturnsCalc } /> 
                     <b> if</b> {coinNames}'s price is ${ProPriceValues} per coin.
                </p> */}
            <div className="mb-2 mt-8">
                <label htmlFor="price" class="block text-light-green0 font-normal text-2xl">Total Value:</label>
                <p className="libre select-all text-light-green0 text-3xl font-normal"><NumberFormat  thousandSeparator={true} prefix={'$'} displayType={'text'} value={ProReturnsCalc} suffix={'🤑'} />
                    </p>
            </div>
            <div className="mb-2 mt-8">
                <label htmlFor="price" class="block text-light-green0 font-normal text-2xl">Profits:</label>
                <p className="libre font-medium select-all text-light-green0 text-3xl"><NumberFormat  thousandSeparator={true} prefix={'$'} displayType={'text'} value={profit} suffix={'🚀'} />
                    {/* {profit} */}
                    </p>
            </div>
            <br/>
           

        </div>
        </Fade>
      : null } 
         
 
        </div>
    )
}

export default Results
