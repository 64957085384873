import React, { useState, useContext, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import index from './index'
import Input from './components/Input';
import Navbar from './components/Navbar';
import Results from './components/Results';
import Footer from "./components/Footer";
import {StateContext, State } from './components/State';
import Lines from "./components/assets/lines.png";
import {   BrowserRouter as Router, Switch, Route,Link } from 'react-router-dom';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-38753559-4');
ReactGA.pageview(window.location.pathname + window.location.search);

function Calculator() {
    const initGA = () => {   
        ReactGA.initialize('UA-38753559-4'); // put your tracking id here    
      }
    useEffect(() => { initGA(); }, []);
    
    return (
        <div className="body">
        <State>
        <Router>
            <div className="container mx-auto">
            {/* <img src={Lines} alt="Lines" className="lines select-none" /> */}
                {/* <Navbar /> */}
                <div className="pt-20 pb-20">
                    <div className="title text-center">
                        <h1 className="text-6xl">Crypto Calculator</h1>
                        <p className="pt-5">Simple Crypto currency profit calculator 🚀🚀</p>
                    </div>
                    <div className="flex calculator pt-16 calc-box">
                        <div className="input">
                            <Input /> {/* input component */}
                        </div>
                            <Results /> {/* result component */}
                    </div>
                </div>  
                <Footer/>
            </div>
        </Router>
        </State>
        </div>

    );
}

export default Calculator;

if (document.getElementById('root')) {
    ReactDOM.render(<Calculator />, document.getElementById('root'));
}
